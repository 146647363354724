import dayjs, { UnitType } from 'dayjs';

import { i18n, t } from '@shared/i18n';

import { getCurrentTimeInUTC } from './dates';

export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
export const DEFAULT_MONTH_ONLY_FORMAT = 'MMMM YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'MMM D, YYYY h:mm:ss A';

const twoDecimals = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
const noDecimals = { minimumFractionDigits: 0, maximumFractionDigits: 0 };

export const formatDecimalNumber = (val: number, showDecimals?: boolean) => {
	return new Intl.NumberFormat(i18n.language, showDecimals ? twoDecimals : noDecimals).format(val);
};

export const formatDecimalNumberWhenNeeded = (val: number) => {
	return new Intl.NumberFormat(i18n.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val);
};

export const formatPercentage = (val: number, showDecimals?: boolean) => {
	return new Intl.NumberFormat(i18n.language, { style: 'percent', ...(showDecimals ? twoDecimals : noDecimals) }).format(val / 100);
};

export const formatAmountWithCurrencyCode = (amount: number, currencyCode: string) => {
	if (amount !== 0 && !amount) {
		return null;
	}

	if (!currencyCode) {
		return formatDecimalNumber(amount, true);
	}

	try {
		// Need ES 2020 to use 'currencyDisplay', but we've been using it all along
		return amount.toLocaleString(i18n.language, {
			style: 'currency',
			currency: currencyCode,
			currencyDisplay: 'code',
		} as unknown as Intl.NumberFormatOptions);
	} catch (error) {
		// Handle unknown currency codes errors
		if (error instanceof RangeError) {
			return `${currencyCode} ${formatDecimalNumber(amount, true)}`;
		}

		throw error;
	}
};

export const formatNumber = (val: number, isUSDollarAmount = false, shouldAbbreviate = false): string => {
	const isAbbreviatedLargeNumber = shouldAbbreviate && Math.abs(val) >= 1000;

	if (!isUSDollarAmount) {
		return new Intl.NumberFormat(i18n.language, { notation: isAbbreviatedLargeNumber ? 'compact' : 'standard' }).format(val);
	} else {
		// Only abbreviate (and display decimals) for numbers that will actually be abbreviated.
		const options: Intl.NumberFormatOptions = {};
		if (isAbbreviatedLargeNumber) {
			options.notation = 'compact';
			options.minimumFractionDigits = 0;
			options.maximumFractionDigits = 1;
		}

		const value = new Intl.NumberFormat(i18n.language, options).format(val);

		// add USD in a locale-safe way
		// eslint-disable-next-line id-denylist
		return t('General.USDFormat', { number: value });
	}
};

/**
 * Function to format a date in UTC.
 * Note that if the input date-time string does not have timezone information, it will be treated as the user's local timezone.
 * It first converts the input date to a Day.js object in local time, then converts it to UTC,
 * and finally formats it in the 'MMM D, YYYY h:mm A zZ' format.
 */
export const formatDateInUtc = (date: Date | string): string => {
	return (date && dayjs(date).utc().tz('Etc/UTC').format('MMM D, YYYY h:mm A zZ')) || '';
};

/**
 * Formats a date into a string representation using the local timezone.
 * If no format is provided, the default format is used.
 */
export const formatDate = (date: Date | string, format?: string): string => {
	return (date && dayjs(date).format(format ?? DEFAULT_DATE_FORMAT)) || '';
};

/**
 * Function to format a date-time string according to the user's local timezone.
 * Note that if the input date-time string does not have timezone information, it will be treated as the user's local timezone.
 * This function is typically used when the portal needs to display dates that are received in UTC from the backend,
 * but need to be displayed according to the user's local timezone settings.
 */
export const formatDateTimeAutoTz = (date: Date | string, format?: string): string => {
	return (
		(date &&
			dayjs(date)
				.tz(dayjs.tz.guess())
				.format(format || 'MMM D, YYYY h:mm A z')) ||
		''
	);
};

export const parseDateTime = (date: string) => {
	const dateWithoutTimezone = date.slice(0, -4);
	const newDate = dayjs(dateWithoutTimezone, 'MMM D, YYYY h:mm A', true);

	return newDate;
};

export const formatDateTimeInShortAutoTz = (date: Date | string): string => {
	return (date && dayjs(date).tz(dayjs.tz.guess()).format('MMMM D, YYYY')) || '';
};

export const getTimeFromNow = (date: Date | string): string => {
	return (date && dayjs(date).fromNow()) || '';
};

export const getStartOfDay = (date: Date | null | undefined): Date => {
	return (date && dayjs(date).local().startOf('day').toDate()) || null;
};

export const getEndOfDay = (date: Date | null | undefined): Date => {
	return (date && dayjs(date).local().endOf('day').toDate()) || null;
};

export const getStartOfMinute = (date: Date | null | undefined): Date => {
	return (date && dayjs(date).local().startOf('minute').toDate()) || null;
};

export const getEndOfMinute = (date: Date | null | undefined): Date => {
	return (date && dayjs(date).local().endOf('minute').toDate()) || null;
};

export const uppercaseFirstCharacter = (input: string): string => {
	return input.charAt(0).toLocaleUpperCase() + input.slice(1);
};

export const isInThePast = (date: Date | string) => {
	return date && dayjs(date).utc().isBefore(getCurrentTimeInUTC());
};

export const isInTheFuture = (date: Date | string) => {
	return date && dayjs(date).utc().isAfter(getCurrentTimeInUTC());
};

export const getDateDiff = (dateA: Date | string | number, unit: UnitType = 'days') => {
	return dateA && dayjs(dateA).utc().diff(undefined, unit);
};
