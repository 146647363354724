/* eslint-disable no-bitwise */

// generate RFC4122 compliant guid (https://www.ietf.org/rfc/rfc4122.txt)
// taken from: https://stackoverflow.com/a/8809472
export const generate = () => {
	let d = new Date().getTime();

	if (performance?.now !== undefined) {
		d += performance.now(); // use high-precision timer if available
	}

	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);

		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export const guidRegexString = '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

export const isGuid = (s: string) => {
	return new RegExp(guidRegexString).test(s);
};
