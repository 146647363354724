import { NODE_ENVS } from './nodeEnvironments';
import { RoutePaths } from './utilities/routePaths';

export const RISK_API_ROLE = 'Risk_API';
export const SANDBOX_RISK_API_ROLE = 'Sandbox_Risk_API';

export const LOCALSTORAGE_SHOW_GEOGRAPHY_SELECTION = 'DFP/GA-10.1/ShowGeographySelection';
export const LOCALSTORAGE_SELECTED_GEOGRAPHY = 'DFP/GA-10.1/SelectedGeography';
export const USE_MOCKS = 'dfp/usemocks';

// These are used for selecting Sparta env via URL, e.g. http:localhost/?DFP/UseTest=true
export const USE_PERF2 = 'dfp/useperf2';
export const USE_PERF = 'dfp/useperf';
export const USE_POC = 'dfp/usepoc';
export const USE_TEST = 'dfp/usetest';
export const USE_INTZ = 'dfp/useintz';
export const USE_ENDR = 'dfp/useendr';
export const USE_LOCAL = 'dfp/uselocal';
export const USE_CUSTOM = 'dfp/usecustom';
export const USE_NS = 'dfp/usens';
export const USE_NS1 = 'dfp/usens1';
// Store selected API type, e.g. Test, Custom
export const LOCALSTORAGE_SPARTA_API = 'DFP/SpartaApi';
// Store custom endpoint prefix
export const LOCALSTORAGE_SPARTA_BASE_URL = 'DFP/SpartaBaseUrl';
export const LOCALSTORAGE_LAST_TENANT = 'DFP/LastTenant';

export const LOCALSTORAGE_PBI_CACHE_PREFIX = 'DFP/PBICache/';

export const DATA_UPLOAD_VALIDATION_COUNT_LOCALSTORAGE_KEY = 'DFP/DAtaUploadValidationCount';

export const PROMO_CODE_LOCALSTORAGE_KEY = 'DFP/PromoCode';
export const OFFER_ID_LOCALSTORAGE_KEY = 'DFP/OfferId';

export const DEBOUNCE_FOR_ONCHANGE = 500;
export const DEBOUNCE_ON_API_CALL = 1000;
export const DELAY_CALLOUT_DISMISS = 500;

export const LOW_RESOLUTION_BREAKPOINT = 640;
export const CENTERED_MESSAGE_BREAKPOINT = 340;

export const MAX_LENGTH_OF_NAME = 64;
export const EXTERNAL_CALL_MAX_TIMEOUT_MS = 5000;

export const isLocalDevelopment = process.env.NODE_ENV === NODE_ENVS.Development;
export const isTest = process.env.NODE_ENV === NODE_ENVS.Test;
export const isAppleWebkit = navigator.vendor.toLocaleLowerCase().includes('apple');

export const PSP_NAV_VERSION = 'PSP-1.0';
export const LANDING_PAGE_ROUTE_SUMMARY = RoutePaths.AssessmentReports.Summary;
export const LANDING_PAGE_ROUTE_SEARCH = RoutePaths.Search;
export const LANDING_PAGE_ROUTE_API_MANAGEMENT = RoutePaths.Data.ApiManagement.Root;
export const LANDING_PAGE_ROUTE_EVENT_TRACING = RoutePaths.Data.EventTracing;
export const LANDING_PAGE_ROUTE_CASE_MANAGEMENT = RoutePaths.CaseManagement.QueueDashboard;

export const SESSION_STORAGE_IS_CRITICAL_NOTIFICATIONS_MESSAGE_REMOVED = 'DFP/IsCriticalNotificationsMessageRemoved';

export const CUSTOM_SETTINGS = 'CustomSettings';

export const CR_LF = '\r\n';

export const MANUAL_REVIEW_ANALYST = 'ManualReviewAnalyst';
