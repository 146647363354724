const perfMarkerPrefix = '__DFP_PERF_MARKER__';

const performanceAPI =
	typeof window !== 'undefined' &&
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	((window as any).performance || (window as any).webkitPerformance || (window as any).msPerformance || (window as any).mozPerformance);

const performanceSupported = performanceAPI && typeof performanceAPI.mark === 'function' && typeof performanceAPI.measure === 'function';

if (performanceSupported) {
	// Needed for performance profiler tool to identify our markers
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-underscore-dangle
	(window as any).__PERF_MARKER_PREFIX__ = perfMarkerPrefix;
	performanceAPI.mark(`${perfMarkerPrefix}PerfProfilerLoaded`);
}

export const startMarker = (id: string) => {
	if (performanceSupported) {
		performanceAPI.mark(`${perfMarkerPrefix}${id}_START`);
	}
};

export const setGlobalMarker = (id: string) => {
	if (performanceSupported) {
		performanceAPI.mark(`${perfMarkerPrefix}${id}`);
	}
};

export const endMarker = (id: string) => {
	if (performanceSupported) {
		const endId = `${perfMarkerPrefix}${id}_END`;
		performanceAPI.mark(endId);
		const startId = `${perfMarkerPrefix}${id}_START`;
		performanceAPI.measure(id, startId, endId);
	}
};

const getDataPointForTask = (taskName: string) => {
	if (performanceSupported) {
		const dataPoints = performanceAPI.getEntriesByName(taskName);
		if (dataPoints && dataPoints.length > 0) {
			return dataPoints.at(-1);
		}
	}

	return null;
};

export const getDurationForTask = (taskName: string): number => {
	const entry = getDataPointForTask(taskName);
	if (entry) {
		return entry.duration;
	}

	return 0;
};
