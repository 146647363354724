/* eslint-disable no-console */

import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { isLocalDevelopment } from '../constants';
import { Dictionary } from '../interfaces/Dictionary';
import * as telemetry from './telemetry';

const logTraceEvent = (msg: string, severity: SeverityLevel, consoleFn: (msg: string) => void, props?: Dictionary<string>) => {
	telemetry.trackTrace(msg, severity, props);

	if (isLocalDevelopment) {
		consoleFn(msg);
	}
};

const logExceptionEvent = (error: Error, consoleFn: Console['error'], props?: Dictionary<string>) => {
	telemetry.trackException(error, props);

	if (isLocalDevelopment) {
		consoleFn(error);
	}
};

export const logTrace = (msg: string, props: Dictionary<string> = null) => logTraceEvent(msg, SeverityLevel.Verbose, console.trace, props);
export const logInfo = (msg: string, props: Dictionary<string> = null) => logTraceEvent(msg, SeverityLevel.Information, console.info, props);
export const logWarn = (msg: string, props: Dictionary<string> = null) => logTraceEvent(msg, SeverityLevel.Warning, console.warn, props);
export const logError = (error: Error, props: Dictionary<string> = null) => logExceptionEvent(error, console.error, props);
