import { ApiConfig } from '@api/base/models/ApiConfig';
import { ApiConfigs } from '@api/base/models/ApiConfigs';

import { isTest } from '../constants';

const TEST_API_CONFIG = { Test: { BaseUri: '{someSpecialTestToken}/some/end/point/here' } };

export const getApiConfig = (apiName: string): ApiConfig => {
	const configs: ApiConfigs = isTest ? TEST_API_CONFIG : window.apiConfig;

	return configs[isTest ? 'Test' : apiName];
};
