import dayjs from 'dayjs';

import { CalendarStrings } from '@fluentui/react-datepicker-compat';
import { t } from '@shared/i18n';

export const getLatestDate = (a?: Date, b?: Date): Date => {
	if (!b) {
		return a;
	}
	if (!a) {
		return b;
	}

	return a.getTime() > b.getTime() ? a : b;
};

export const datePickerCalendarStrings = () => {
	return (t('DatePicker', { returnObjects: true }) as unknown as CalendarStrings) || null;
};

export const getTimeNHoursAgo = (hours: number) => {
	const startTime = new Date();
	startTime.setHours(startTime.getHours() - hours);

	return startTime;
};

export const getTimeNDaysAgo = (days: number) => {
	const startTime = new Date();
	startTime.setDate(startTime.getDate() - days);

	return startTime;
};

export const getTimeNMonthsAgo = (months: number) => {
	const startTime = new Date();
	startTime.setMonth(startTime.getMonth() - months);

	return startTime;
};

export const getTimeNHoursAfter = (hours: number) => {
	const startTime = new Date();
	startTime.setHours(startTime.getHours() + hours);

	return startTime;
};

export const getTimeNDaysAfter = (days: number) => {
	const startTime = new Date();
	startTime.setDate(startTime.getDate() + days);

	return startTime;
};

export const getTimeNMinutesAfter = (minutes: number) => {
	const startTime = new Date();
	startTime.setMinutes(startTime.getMinutes() + minutes);

	return startTime;
};

export const isValidISO8601Date = (value: string) => {
	/**
	 * This regex validate ISO 8601 date strings in a similar manner as Moment.js.
	 * However, this regex does not validate '2023-02-29' as an invalid date (2023 is not a leap year, and February 29th does not exist in the year).
	 */
	const ISO8601Regex = /^(?:[+-]\d{6}|\d{4})(?:-\d{2}(?:-\d{2})?)?(?:T\d{2}:\d{2}(?::\d{2})?(?:[.,]\d+)?(?:Z|[+-]\d{2}(?::?\d{2})?)?)?$/;

	// leading spaces are valid according to dayjs but the, DateTimeOffset method used in the BE rejects it
	if (/\s/g.test(value)) {
		return false;
	}

	return dayjs(value).isValid() && ISO8601Regex.test(value);
};

export const sortByTimestamp =
	<T>(sortColumn: keyof T, sortAscending: boolean) =>
	(a: T, b: T) => {
		const firstValue = a[sortColumn] as unknown as string | number | Date;
		const secondValue = b[sortColumn] as unknown as string | number | Date;

		const aDate = new Date(firstValue).valueOf();
		const bDate = new Date(secondValue).valueOf();

		if (aDate === bDate) {
			return 0;
		}

		return sortAscending ? (aDate > bDate ? 1 : -1) : aDate < bDate ? 1 : -1;
	};

export const getPreviousDateTimeFromNow = (
	units: Partial<{
		milliseconds: number;
		seconds: number;
		minutes: number;
		hours: number;
		days: number;
		months: number;
		years: number;
		weeks: number;
	}>,
) => dayjs().subtract(dayjs.duration(units)).toDate();

export const getCurrentTime = () => dayjs().toDate();

/**
 * Returns a Day.js object in UTC that represents the current date and time.
 */
export const getCurrentTimeInUTC = () => dayjs.utc();

export const getCurrentTimeAsISOString = () => dayjs().toISOString();

export const isTimePlusMinutesBeforeNow = (date: Date | string, min: number) => {
	return dayjs(date).utc().add(min, 'minutes').isBefore(getCurrentTimeInUTC());
};

/**
 * Leave 'date' blank to check the current date.
 */
export const isDateInRangeInclusive = (min: Date | string, max: Date | string, date?: Date | string) => {
	const dateToCheck = dayjs(date);

	return dayjs(dateToCheck).diff(min) >= 0 && dayjs(dateToCheck).diff(max) <= 0;
};

export const isEpochMinDate = (dateStr: string) => {
	if (!dateStr) {
		return false;
	}

	const date = new Date(dateStr);
	const epochDate = new Date(0);

	return (
		date.getUTCFullYear() === epochDate.getUTCFullYear() &&
		date.getUTCMonth() === epochDate.getUTCMonth() &&
		date.getUTCDate() === epochDate.getUTCDate()
	);
};

export const combineDateWithTime = (datePart: Date, timePart: Date) => {
	return dayjs(datePart)
		.hour(timePart.getHours())
		.minute(timePart.getMinutes())
		.second(timePart.getSeconds())
		.millisecond(timePart.getMilliseconds())
		.toDate();
};
