export const DFP_CORRID_HEADER = 'x-ms-correlation-id';
export const DFP_ENVID_HEADER = 'x-ms-dfpenvid';
export const DFP_TENANTID_HEADER = 'x-ms-dfptenantid';
export const DFP_ASSIGNEDROLES_HEADER = 'x-ms-dfpassignedroles';
export const AZUREROLES_HEADER = 'x-ms-azureroles';
export const SPARTA_ENV_HEADER = 'x-ms-spartaenv';
export const SPARTA_ENV_BASE_URL = 'x-ms-spartaenv-baseurl';
export const START_MODIFIER_KEY = 'start';
export const END_MODIFIER_KEY = 'end';
export const DFP_USES_CUSTOM_AUTH = 'x-ms-custom-auth';
