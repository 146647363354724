import * as React from 'react';

import { ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web';

import { Dictionary } from '../interfaces/Dictionary';
import * as guid from '../utilities/guid';
import { endMarker, getDurationForTask, startMarker } from './Performance';
import { AppInsights } from './appInsights';
import { DFP_CORRID_HEADER, END_MODIFIER_KEY, START_MODIFIER_KEY } from './constants';
import { EventType } from './models/EventType';
import { TelemetryId } from './telemetryConstants';
import { getEventProperties } from './utils';

export const trackTrace = (message: string, severityLevel: SeverityLevel, properties?: Dictionary<string>): void => {
	AppInsights.trackTrace({
		message,
		severityLevel,
		properties,
	});
};

export const trackException = (exception: Error, properties?: Dictionary<string>): void => {
	AppInsights.trackException({
		exception,
		severityLevel: SeverityLevel.Error,
		properties,
	});
};

export const trackEventStart = (id: string, eventName: string, eventType: EventType, properties?: Dictionary<string>): void => {
	startMarker(id);
	AppInsights.trackEvent({
		name: eventName,
		properties: { activityId: id, eventName, eventType, eventModifier: START_MODIFIER_KEY, ...properties },
	});
};

export const trackEventEnd = (id: string, eventName: string, eventType: EventType, properties?: Dictionary<string>): void => {
	endMarker(id);
	const eventDuration = getDurationForTask(id);

	AppInsights.trackEvent({
		name: eventName,
		properties: { activityId: id, eventName, eventType, eventModifier: END_MODIFIER_KEY, ...properties },
		measurements: { eventDuration },
	});
};

export const trackSingleEvent = (eventName: string, eventType: EventType, properties?: Dictionary<string>): void => {
	AppInsights.trackEvent({
		name: eventName,
		properties: { eventName, eventType, ...properties },
	});
};

/**
 * tracks a (async or synchronous) callback function to Application Insights
 *
 * @callbackFn the callback function to be executed. can be async or synchronous
 * @telemetryId the ID used to query this callback function execution in Application Insights
 * @customProperties any custom properties that should be sent to Application Insights can be an object or a callback function that returns an object. the callback function is useful for sending dynamic properties that are not available at the time of the callbackFn execution
 */
export const trackCallbackFn = <T extends (...args: unknown[]) => void | Promise<void>>(
	callbackFn: T,
	telemetryId: TelemetryId | string,
	customProperties?: ICustomProperties | (() => ICustomProperties),
): T =>
	(async (...args: unknown[]) => {
		let computedCustomProperties = customProperties;
		const changeEvent = args[0] as React.ChangeEvent;
		const htmlElement = changeEvent.currentTarget as HTMLElement;
		const name = telemetryId ? telemetryId : (changeEvent.currentTarget as HTMLElement).id;

		// if a callbackFn is provided, track the callbackFn duration
		if (callbackFn) {
			startMarker(name);
			await callbackFn(...args);
			endMarker(name);
		}

		if (typeof customProperties === 'function' && typeof customProperties.call === 'function') {
			computedCustomProperties = customProperties();
		}

		AppInsights.trackEvent({
			name,
			properties: {
				...getEventProperties(name, htmlElement),
				...(callbackFn && { eventDuration: getDurationForTask(name) }),
				...(computedCustomProperties && computedCustomProperties),
			},
		});
	}) as T;

export interface TrackOutgoingRequestParams {
	name: string;
	target: string;
	duration: number;
	success: boolean;
	responseCode: number;
	correlationId: string;
	retryCount: number;
	startTime: Date;
	isPolling?: boolean;
}

export const trackOutgoingRequest = ({
	name,
	target,
	duration,
	success,
	responseCode,
	correlationId,
	retryCount,
	startTime,
	isPolling = false,
}: TrackOutgoingRequestParams): void => {
	AppInsights.trackDependencyData({
		name,
		target,
		duration,
		success,
		responseCode,
		startTime,
		id: guid.generate(),
		correlationContext: correlationId,
		properties: {
			retryCount,
			[DFP_CORRID_HEADER]: correlationId,
			...(isPolling && { isPolling }),
		},
	});
};
